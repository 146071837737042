import { Section } from '../../../../components/common/section';
import { SectionAnchor } from '../../../../models/section-anchor';
import perfumeImg from './img/product.png';
import txImg from './img/tx.png';
import walletImg from './img/wallet.png';
import ccImg from './img/control-centre.png';

import './solutions-section.scss';

export const SolutionsSection = () => (
  <Section
    anchor={ SectionAnchor.Solutions }
    offset={ -100 }
    className='solutions-section'
  >
    <div className='container'>
      <h5 className='section-header'>Solutions</h5>
      <h2 className='section-subheader'>Payment Software to maximize your profit</h2>
      <p className='section-text'>
        Keep your business competitive with our value-added payment solutions.
        Optimize your workflows, enhanced customer experience and increase sales
      </p>

      <div className='solutions-grid'>
        <div className='grid-row'>
          <div className='grid-item wide-mobile-img'>
            <img src={ perfumeImg } alt='Perfume' />
          </div>
          <div className='grid-item'>
            <div className='item-text-container'>
              <h2 className='section-subheader'>Payment Gateway SaaS</h2>
              <p className='section-text'>
                {/* eslint-disable-next-line max-len */}
                Payment Gateway as a service solution provides you with the complexity, flexibility and efficiency to improve the growth of your business profitability
              </p>
              <ul>
                <li>Multiprocessing</li>
                <li>Payments & Payouts</li>
                <li>Multiple currencies & methods</li>
                <li>Routing & Cascading payments</li>
                <li>Tokenization</li>
                <li>Recurring payments</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='grid-row'>
          <div className='grid-item'>
            <img src={ txImg } alt='Transaction' />
          </div>
          <div className='grid-item'>
            <div className='item-text-container'>
              <h2 className='section-subheader'>Payment Gateway WL</h2>
              <p className='section-text'>
                {/* eslint-disable-next-line max-len */}
                White Label Payment Gateway software is designed for PSP, ISO, Acquirers and Merchants to let operate payments with zero development cost under own brand. Focus on strategic business issues and let us take care of the technical ones
              </p>
              <ul>
                <li>Full customization</li>
                <li>Individual features development</li>
                <li>Customizable rules</li>
                <li>All-In-One Dashboard</li>
                <li>Analytic & Reports</li>
                <li>PCI DSS Level 1 compliant</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='grid-row'>
          <div className='grid-item wide-mobile-img'>
            <img src={ walletImg } alt='Wallet' />
          </div>
          <div className='grid-item'>
            <div className='item-text-container'>
              <h2 className='section-subheader'>Digital wallet</h2>
              <p className='section-text'>
                {/* eslint-disable-next-line max-len */}
                Wallet software is a combination of digital bank, e-money and other fintech services in one fintech super app. It’s a great solution for managing virtual and prepaid cards, assets, loans, deposits, investing & managing other fintech services
              </p>
              <ul>
                <li>For both B2B and B2C use</li>
                <li>Mobile & Web app</li>
                <li>IBAN, Emoney, Crypto, Cards</li>
                <li>Flexible replenishment and withdrawal</li>
                <li>Сheaper transaction costs</li>
                <li>Localization and customization possibility</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='grid-row'>
          <div className='grid-item'>
            <img src={ ccImg } alt='Control centre' />
          </div>
          <div className='grid-item'>
            <div className='item-text-container'>
              <h2 className='section-subheader'>Financial Control Centre</h2>
              <p className='section-text'>
                {/* eslint-disable-next-line max-len */}
                Financial Control Centre is a software that enables to control laws requirements implementation. The platform provides the main processes
              </p>
              <ul>
                <li>KYC & AML policies implementation</li>
                <li>Full payment flow control</li>
                <li>Tax management</li>
                <li>Real-time analytics & reports</li>
                <li>Support & Maintenance</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
);
