/* eslint-disable max-len */
export const GeneralItemsSection = () => (
  <div className='section general-items-section'>
    <div className='container'>
      <h5>GENERAL TERMS</h5>
      <h2>Service Introduction</h2>
      <li>Paytechlab provides an integrated monetization suite including an application programming interface and supporting infrastructure that enables You to receive end user payments and alternative payments for digital goods and services (the “Paytechlab Service”).</li>
      <li>You will use the Paytechlab Service for the purpose of collecting payments from end users, according to the terms of this Agreement, and solely for the digital goods and/or services You have listed in Your Paytechlab Admin account.</li>

      <h2>Your Paytechlab Account</h2>
      <li>To register for a Paytechlab Account, You or the person or people submitting the application (Your “Representative”) must provide us with Your business or trade name, address, email, phone number, business identification number, URL, the nature of Your business or activities, and certain other information about You that we require. We may also collect, amongst others, personal information (including name, birthdate, and government-issued identification number) about Your beneficial owners, principals, and Your Paytechlab Account administrator. Until You have submitted, and we have reviewed and approved all required information, Your Paytechlab Account will be available to You on a preliminary basis only, and we may terminate it at any time and for any reason.</li>
      <li>Paytechlab and its affiliates may provide Services to You or Your affiliates in other countries or regions as permitted or under separate agreements. Only businesses (including sole proprietors), bona fide charitable organizations, are eligible to apply for a Paytechlab Account to use the Services described in this Agreement.</li>
      <li>You may only use Paytechlab Services to facilitate Transactions with Your Customers. You may not use Paytechlab Services to send money to others, to conduct any personal or noncommercial transactions, or for any other purposes prohibited by this Agreement.</li>

      <h2>Account Validation and Underwriting</h2>
      <li>At any time during the term of this Agreement and Your use of the Paytechlab Services, we may require additional information from You to verify beneficial ownership or control of the business, validate information You provided, verify Your or Your Representative’s identity, and assess the risk associated with Your business. This additional information may include business invoices, copies of government-issued identification, business licenses, or other information related to Your business, its beneficial owners or principals. We may also request that You provide copies of financial statements or records pertaining to Your compliance with this Agreement, or require You to provide a personal or company guarantee. Your failure to provide this information may result in suspension or termination of Your Paytechlab Account. Further, Paytechlab reserves the right to suspend or cancel any transactions processed via the services in case of Your failure to comply with this agreement.</li>
      <li>You authorize us to retrieve information about You from our service providers, including credit and information bureaus. You acknowledge that this may include Your name, addresses, credit history, and other data about You or Your Representative. You acknowledge that we may use Your information to verify any other information You provide to us, and that any information we collect may affect our assessment of Your overall risk to our business. You acknowledge that in some cases, such information may lead to suspension or termination of Your Paytechlab Account. Paytechlab may periodically update this information as part of our underwriting criteria and risk analysis procedures.</li>

      <h2>Changes In Your Business Profile</h2>
      <li>You agree to keep the information in Your Paytechlab Account current. You must promptly update Your Paytechlab Account with any changes affecting You, the nature of Your business activities, Your Representatives, beneficial owners, principals, or any other pertinent information. We may suspend Your Paytechlab Account or terminate this Agreement if You fail to keep this information current.</li>
      <li>You also agree to promptly notify us in writing no more than three days after any of the following occur: You are the subject of any voluntary or involuntary insolvency petition or proceeding, receivership, bankruptcy, or similar action; there is an adverse change in Your financial condition; there is a planned or anticipated liquidation or substantial change in the basic nature of Your business; You transfer or sell 25% or more of Your total assets, or there is any change in the control or ownership of Your business or parent entity; or You receive a judgment, writ or warrant of attachment or execution, or levy against 25% or more of Your total assets.</li>

      <h2>Your Obligations</h2>
      <li>You are obliged to provide Paytechlab all the details in respect of its offices locations, their registered addresses and contacts, all "doing business as" (DBA) names used by You, key personnel, ownership structure, a complete list with the detailed description of the services and goods provided by You as listed in Your Paytechlab Admin account and/or submitted to Paytechlab in accordance to this Agreement, and any business strategy to enlarge/reduce the list of services/goods associated with Your Paytechlab Admin account. You shall immediately inform Paytechlab in writing of any significant litigation, enforcement action, governmental inquiry, claim, or other fact that relates to the performance of this Agreement, any changes to its business model (including any change of control and/or constitution), goods and/or services it sells, leases or distributes or of any changes in regulatory requirements to which it is subject (including but not limited to changes to or the revocation of the licenses it requires for the business), which might have an adverse impact/effect on Paytechlab’s compliance with applicable laws and any regulatory requirements.</li>
      <li>You acknowledge that Paytechlab has the right to terminate this Agreement with immediate effect or amend the terms of this Agreement, including but not limited to Revenue Share, payout terms, and rolling reserve, in response to Your disclosures or any change of circumstances under this Section. If You do not provide the required information to Paytechlab and apply preventive actions, in the case that Paytechlab or You discover suspicious and/or fraudulent activity, You shall indemnify Paytechlab against all losses arising out of Your failure to notify Paytechlab of any changes that are relevant for compliance with regulatory requirements and standards applicable to Paytechlab or You.</li>
      <li>In the event that You owe any amount to Paytechlab for any reason under this Agreement, You shall also be liable for any additional costs associated with the collection of the amount owed, including without limitation attorneys’ fees and expenses, costs of any arbitration or court proceeding, collection agency fees and any applicable interest. Paytechlab may also collect such amounts by deducting the amount from Your account. Your failure to fully pay the amounts owed will be a material breach of this Agreement.</li>

      <h2>Service Limitations, Prohibited Activities and Compliance with Laws</h2>
      <li>You may not use the Services, for Your benefit or the benefit of another, for any activities Paytechlab has identified as a prohibited business or activity (collectively, “Prohibited Businesses”). Prohibited Businesses include use of the Services in or for the benefit of a country, organization, entity, or person embargoed or blocked by any government, including those on sanctions lists identified by the Europe Union. If You are uncertain whether a category of business or activity is prohibited or have questions about how these restrictions apply to You, please contact us. We may add to or update the Prohibited Businesses list at any time.</li>
      <li>You may not use the Services to facilitate illegal Transactions or to permit others to use the Services for noncommercial, personal, or household purposes. In addition, You may not allow, and may not allow others to: (I) access or attempt to access non-public Paytechlab systems, programs, data, or services; (II) copy, reproduce, republish, upload, post, transmit, resell, or distribute in any way, any data, content, or any part of the Services, Documentation, or our website except as expressly permitted by applicable Laws; (III) act as service bureau or pass-through agent for the Services with no added value to Customers; (IV) transfer any rights granted to You under this Agreement; (V) work around any of the technical limitations of the Services or enable functionality that is disabled or prohibited; (VI) reverse engineer or attempt to reverse engineer the Services except as expressly permitted by Laws; (VII) perform or attempt to perform any actions that would interfere with the normal operation of the Services or affect use of the Services by our other users; or (VIII) impose an unreasonable or disproportionately large load on the Service.</li>
      <li>You agree that You will deliver the content and service in compliance with all applicable local, state, national and international laws, rules and regulations, including any laws regarding privacy and the transmission of technical data exported from Your country of residence. You will not authorize nor otherwise encourage any third party to (a) interfere or attempt to interfere with the proper working of the Paytechlab Service or prevent others from using the Paytechlab Service; or (b) use the Paytechlab Service for any fraudulent or unlawful purpose. Violation of any of the foregoing may result in immediate termination of this Agreement, at Paytechlab's sole discretion, and may subject You to legal penalties and other legal consequences. Paytechlab reserves the right, but will have no obligation, to review Your display of and use of the Paytechlab Service in order to determine whether a violation of this Agreement has occurred or to comply with any applicable law, regulation, legal process, or governmental request.</li>

      <h2>Representations and Warranties</h2>
      <li>Without limiting any other representation, warranty or covenant herein, each party hereby represents and warrants to the other party that: (a) it has the full right, power and authority to enter into this Agreement; (b) this Agreement is a valid and binding obligation of such party; and (c) it has obtained and shall maintain throughout the term of this Agreement all necessary licenses, authorizations, approvals and consents to enter into and perform its obligations hereunder in compliance with all applicable laws, rules and regulations.</li>
      <li>You represent and warrant to Paytechlab that it lawfully owns the rights to all content on the Projects and its website, including any trademarks, trade names, copyrights, and other intellectual property.</li>
    </div>
  </div>
);
